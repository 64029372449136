import { render, staticRenderFns } from "./Archive.vue?vue&type=template&id=23f4bfba&scoped=true&"
import script from "./Archive.vue?vue&type=script&lang=js&"
export * from "./Archive.vue?vue&type=script&lang=js&"
import style0 from "./Archive.vue?vue&type=style&index=0&id=23f4bfba&scoped=true&lang=scss&"
import style1 from "./Archive.vue?vue&type=style&index=1&lang=css&"
import style2 from "./Archive.vue?vue&type=style&index=2&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23f4bfba",
  null
  
)

export default component.exports